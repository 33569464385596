<!-- @file-用户实例列表 -->
<template>
  <div>
    <l-button type="primary" class="c-top_right_btn" @click="$toPage('/auth-app-user-instance/edit', {userId: id})">
      新建用户实例
    </l-button>
    <l-button class="l-mb-10" type="primary" @click="editRow()">新建角色实例</l-button>

    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column data-index="id" title="流水号" />
      <l-table-column data-index="appName" title="系统名字" />
      <l-table-column data-index="appInstanceName" title="实例名字" />
      <l-table-column data-index="roleId" title="类型">
        <template slot-scope="text, scope">{{ scope.roleId?"角色实例":"用户实例" }}</template>
      </l-table-column>
      <l-table-column data-index="roleName" title="角色名字">
        <template slot-scope="text, scope">{{ scope.roleName?scope.roleName:"--" }}</template>
      </l-table-column>
      <l-table-column title="创建时间">
        <template slot-scope="scope">{{ scope.createTime | date }}</template>
      </l-table-column>
      <l-table-column data-index="createName" title="创建人" />
      <l-table-column title="更多">
        <template v-if="scope.isEdit !== 0" slot-scope="scope">
          <l-button
            v-if="!scope.roleId"
            type="link"
            @click="$toPage('/auth-app-user-instance/edit',{userId: id, appUserInstanceId:scope.id})"
          >
            编辑权限
          </l-button>
          <l-button
            v-if="scope.roleId"
            type="link"
            @click="editRow(scope)"
          >
            选择角色
          </l-button>
          <l-button
            v-auth="'AUTH_USER-SAVE'"
            type="link"
            @click="deleteRow(scope.id)"
          >
            删除
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->

    <!-- 保存记录-STR -->
    <l-modal
      v-model="editDialog.show"
      :title="editDialog.data.id?'修改角色实例':'添加角色实例'"
      width="600px"
      cancel-text="取消"
      ok-text="确认"
      @ok="saveDialog"
    >
      <l-form-model
        ref="form"
        :model="editDialog.data"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <l-form-model-item label="系统实例" prop="appInstanceId" required>
          <l-select
            v-model="editDialog.data.appInstanceId"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteMethod"
            :loading="loading"
            placeholder="请选择系统实例"
            :disabled="!!editDialog.data.id"
          >
            <l-select-option
              v-for="item in authAppInstanceList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </l-select-option>
          </l-select>
        </l-form-model-item>
        <l-form-model-item label="关联角色" prop="roleId" required>
          <l-select v-model="editDialog.data.roleId" placeholder="关联角色">
            <l-select-option
              v-for="item in roleList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </l-select-option>
          </l-select>
        </l-form-model-item>
      </l-form-model>
    </l-modal>
    <!-- 保存记录-END -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthAppUserInstanceList',
  data() {
    return {
      id: '',
      searchFilter: {},
      roleList: [],
      loading: false,
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 10,
        list: []
      },
      editDialog: {
        data: {
          id: '',
          appInstanceId: '',
          roleId: ''
        },
        save: false,
        show: false
      }
    }
  },

  computed: {
    ...mapState({
      authAppInstanceList: state => state.auth.authAppInstanceList
    })
  },

  watch: {
    'editDialog.data.appInstanceId': async function(newData, oldData) {
      if (oldData) {
        this.editDialog.data.roleId = ''
      }
      if (!this.editDialog.data.appInstanceId) {
        this.roleList = []
        return
      }

      const data = await this.$store.dispatch('auth/authRoleList', {
        appInstanceId: this.editDialog.data.appInstanceId
      })
      this.roleList = data || []
    }
  },
  mounted() {
    this.id = this.$utils.getParam('userId')
    if (!this.id) {
      // this.$close(true)
      return this.$toPage('/auth-user/list')
    }
    this.search(1)
  },
  activated() {
    this.id = this.$utils.getParam('userId') || this.id
    if (!this.id) {
      // this.$close(true)
      return this.$toPage('/auth-user/list')
    }
    this.search(1)
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page
      const param = {
        page: page || 1,
        size: this.tableData.size
      }

      param.userId = Number(this.id)

      this.tableData.list = []
      this.tableData.isLoading = true

      try {
        const data = await this.$store.dispatch('auth/authUserInstance', param)
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page || 1
      } catch (e) {
        this.tableData.isLoading = false
      }
    },
    resetForm() {
      this.searchFilter = {}
      this.search(1)
    },
    editRow(row) {
      row = row || this.$options.data().editDialog.data
      this.editDialog.data = JSON.parse(JSON.stringify(row))
      for (const key in this.editDialog.errmsg) {
        this.editDialog.errmsg[key] = ''
      }
      this.remoteMethod()
      this.editDialog.show = true
      this.$nextTick(() => {
        this.editDialog.data.roleId = row.roleId || ''
      })
    },
    async remoteMethod(query) {
      this.loading = true
      try {
        await this.$store.dispatch('auth/authAppInstanceList', {
          quickSearch: query,
          type: 1
        })
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    saveDialog() {
      this.editDialog.data.userId = this.id
      try {
        this.$store.dispatch('auth/instanceSaveByRole', this.editDialog.data)
        this.editDialog.show = false
        this.search()
      } catch (e) {
        this.editDialog.show = false
      }
    },
    deleteRow(ids) {
      this.$confirm({
        title: '是否删除，删除后将无法恢复？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await this.$store.dispatch('auth/userInstanceDelete', {
            ids: ids
          })
          this.search()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.errmsg,
.require {
  color: $l-danger;
}
.system-index-btns {
  text-align: right;
  & i {
    font-size: 12px;
    margin-right: 8px;
    vertical-align: top;
  }
  & span {
    display: inline-block;
    vertical-align: top;
  }
}
.device-info {
  .device-info-body {
    margin: 15px 15px 70px;
  }
  .l-dialog__header {
    border-bottom: 1px solid $l-border-base;
  }
  .info-line {
    margin-bottom: 10px;
    span {
      display: inline-block;
      padding: 5px 0;
      color: $l-text-primary;
      font-size: 14px;
      line-height: 24px;
    }
    span.name {
      min-width: 110px;
      line-height: 24px;
    }
  }
}
</style>
